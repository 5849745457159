// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-blog-post-tsx": () => import("./../../../src/blog/blogPost.tsx" /* webpackChunkName: "component---src-blog-blog-post-tsx" */),
  "component---src-documentation-doc-page-tsx": () => import("./../../../src/documentation/DocPage.tsx" /* webpackChunkName: "component---src-documentation-doc-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-automate-i-18-n-translations-js": () => import("./../../../src/pages/automate-i18n-translations.js" /* webpackChunkName: "component---src-pages-automate-i-18-n-translations-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diy-translation-management-js": () => import("./../../../src/pages/diy-translation-management.js" /* webpackChunkName: "component---src-pages-diy-translation-management-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-service-js": () => import("./../../../src/pages/terms-service.js" /* webpackChunkName: "component---src-pages-terms-service-js" */),
  "component---src-pages-translate-angular-i-18-n-js": () => import("./../../../src/pages/translate-angular-i18n.js" /* webpackChunkName: "component---src-pages-translate-angular-i-18-n-js" */),
  "component---src-pages-translate-i-18-n-locale-js": () => import("./../../../src/pages/translate-i18n-locale.js" /* webpackChunkName: "component---src-pages-translate-i-18-n-locale-js" */),
  "component---src-pages-translate-java-properties-js": () => import("./../../../src/pages/translate-java-properties.js" /* webpackChunkName: "component---src-pages-translate-java-properties-js" */),
  "component---src-pages-translate-markdown-js": () => import("./../../../src/pages/translate-markdown.js" /* webpackChunkName: "component---src-pages-translate-markdown-js" */),
  "component---src-pages-translate-php-js": () => import("./../../../src/pages/translate-php.js" /* webpackChunkName: "component---src-pages-translate-php-js" */),
  "component---src-pages-translate-rails-i-18-n-js": () => import("./../../../src/pages/translate-rails-i18n.js" /* webpackChunkName: "component---src-pages-translate-rails-i-18-n-js" */),
  "component---src-pages-translate-react-i-18-n-js": () => import("./../../../src/pages/translate-react-i18n.js" /* webpackChunkName: "component---src-pages-translate-react-i-18-n-js" */),
  "component---src-pages-translate-vue-i-18-n-js": () => import("./../../../src/pages/translate-vue-i18n.js" /* webpackChunkName: "component---src-pages-translate-vue-i-18-n-js" */),
  "component---src-pages-zapier-translation-js": () => import("./../../../src/pages/zapier-translation.js" /* webpackChunkName: "component---src-pages-zapier-translation-js" */)
}

