import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

const globalFont = {
  fontFamily: ["Open Sans", "sans-serif"].join(","),
  fontWeightMedium: 600, // 500
  fontWeightBold: 600, // 700
  fontDisplay: "swap",
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0f4c81",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    ...globalFont,
    fontSize: 14,

    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [globalFont],
        html: {
          height: "100%",
          margin: 0,
          WebkitFontSmoothing: "auto",
        },
        body: {
          height: "100%",
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "rgb(10, 53, 90)", // dark primary
      },
    },
  },
})

export default responsiveFontSizes(theme)
